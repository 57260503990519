import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import PlacesAutocomplete from "./PlacesAutoComplete";
import "react-day-picker/dist/style.css";
import { DayPicker } from "react-day-picker";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { format } from "date-fns";

function PostListing() {
  const [number, updateNumber] = useState();
  const [capacity, updateCapacity] = useState();
  const [address, updateAddress] = useState();
  const [disabled, updateDisabled] = useState(true);
  const [disabledDelete, updateDisabledDelete] = useState(false);
  const [numberOfListings, updateNumberOfListings] = useState();
  const [addressComplete, updateAddressComplete] = useState(false);
  const [placeID, updatePlaceID] = useState();

  const [climateControlled, updateClimateControlled] = useState(false);
  const [driveUpAccess, updateDriveUpAccess] = useState(false);

  const [range, setRange] = useState(undefined);

  const [selected, setSelected] = useState(undefined);

  var footer = <p>Please pick a day.</p>;
  if (selected) {
    footer = <p>You picked {format(selected, "PP")}.</p>;
  }

  /* Update Functions Start */

  function handleNumber(event) {
    updateNumber(event.target.value);
  }

  function handleCapacity(event) {
    updateCapacity(event.target.value);
  }

  useEffect(() => {
    if (
      number === undefined ||
      number === "" ||
      number <= 0 ||
      capacity === undefined ||
      capacity === "" ||
      capacity <= 0 ||
      address === undefined ||
      address === "" ||
      range === undefined ||
      range.from === undefined ||
      range.to === undefined ||
      numberOfListings > 0 ||
      !addressComplete
    ) {
      updateDisabled(true);
    } else {
      updateDisabled(false);
    }
  }, [number, capacity, address, range, addressComplete, numberOfListings]);

  /* Update Functions End */

  useEffect(() => {
    fetch("/api/number", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": "true",
      },
      credentials: "include",
      withCredentials: true,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        updateNumberOfListings(data.number);
        if (Number(data.number) > 0) {
          updateNumberOfListings(data.number);
          updateDisabledDelete(false);
        } else {
          updateDisabledDelete(true);
          updateNumberOfListings(data.number);
        }
      });
  }, [disabledDelete]);

  function deleteClick() {
    fetch("/api/deleteListing", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      withCredentials: true,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        updateNumberOfListings(numberOfListings - 1);
        updateDisabledDelete(true);
      });
  }

  function handleClick(event) {
    fetch("/api/listings", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        placeID: placeID,
        number: number,
        address: address,
        capacity: capacity,
        climateControlled: climateControlled,
        driveUpAccess: driveUpAccess,
        start: range.from.toJSON().slice(0, 10).split("-").reverse().join("/"),
        end: range.to.toJSON().slice(0, 10).split("-").reverse().join("/"),
      }),
      withCredentials: true,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        updateDisabled(true);
        updateDisabledDelete(false);
      })
      .catch((err) => {
        alert(err);
      });
  }

  function filter(d) {
    var today = new Date();
    today.setHours(0, 0, 0, 0);

    return d < today;
  }

  function handleClimateControlled(event) {
    if (event.target.checked) {
      updateClimateControlled(true);
    } else {
      updateClimateControlled(false);
    }
  }

  function handleDriveUp(event) {
    if (event.target.checked) {
      updateDriveUpAccess(true);
    } else {
      updateDriveUpAccess(false);
    }
  }

  return (
    <div>
      <Container style={{ marginTop: "10%" }}>
        <Row className="justify-content-center">
          <Col xs="4" style={{ textAlign: "center" }}>
            <h3>✍️ Create a Posting, Here ✍️</h3>
            <p class="small">
              Please <b>select </b> a date range, and enter a <b>valid</b>{" "}
              address (has to be a building in the US), and complete the
              capacity & price fields to be able to submit. Remember, you can
              only post one listing at a time & book one listing per person at a
              time, and can't book your own. Check-in time is at 7 PM and picked
              up the last day @ noon!{" "}
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center" style={{ marginTop: "1%" }}>
          <Col xs="auto">
            <input
              type="number"
              onChange={handleNumber}
              placeholder="Price Per Night"
            ></input>
          </Col>

          <Col xs="auto">
            <input
              type="number"
              onChange={handleCapacity}
              placeholder="Capacity"
            ></input>
          </Col>

          <Col xs="auto">
            <PlacesAutocomplete
              val={address}
              updateVal={updateAddress}
              updateAddressComplete={updateAddressComplete}
              updatePlaceID={updatePlaceID}
            />
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="justify-content-center">
          <Col style={{ marginLeft: "8%" }} xs="auto">
            <DayPicker
              mode="range"
              selected={range}
              onSelect={setRange}
              disabled={filter}
            />
          </Col>
          <Col xs="auto">
            <Row>
              <label style={{ marginTop: "50%" }}>
                <input
                  onChange={handleClimateControlled}
                  style={{ marginTop: "3%" }}
                  type="checkbox"
                ></input>{" "}
                Climate Controlled
              </label>
            </Row>
            <Row>
              <label style={{}}>
                <input
                  onChange={handleDriveUp}
                  style={{ marginTop: "3%" }}
                  type="checkbox"
                ></input>{" "}
                Drive-Up Access
              </label>
            </Row>
          </Col>
        </Row>

        <Row className="justify-content-center" style={{ marginTop: "3.5%" }}>
          <Col xs="auto">
            <Button
              variant="success"
              disabled={disabled}
              style={{ marginLeft: "1%" }}
              className="btn btn-primary"
              onClick={handleClick}
            >
              Submit
            </Button>
          </Col>

          <Col xs="auto">
            <Button
              onClick={deleteClick}
              disabled={disabledDelete}
              variant="danger"
            >
              Delete Your Posting
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PostListing;
