import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import PopUp from "./popup";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

function Posting(props) {
  const [lat, updateLat] = useState("");
  const [lng, updateLng] = useState("");
  const [url, updateUrl] = useState("");

  useEffect(() => {
    getGeocode({ address: props.location }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      updateLat(lat);
      updateLng(lng);
      updateUrl(
        `https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyCfJZaqhU0j8GQIKVbPjAUuKIUnBbl7xtk&size=400x400&zoom=15&maptype=hybrid&center=${lat},${lng}`,
      );
    });
  });

  function convertStringToDate(dateString) {
    const [day, month, year] = dateString.split("/");
    var res = new Date(year, month - 1, day);
    return res;
  }

  return (
    <Card style={{ width: "17rem" }}>
      <Card.Img variant="top" src={url} style={{ loading: "lazy" }} />
      <Card.Body>
        <Card.Title>
          {props.first} {props.last}
        </Card.Title>
        <Card.Text>
          🚗 <b>{props.time} away</b> <br></br>
          💰 ${props.price}/night <br />⏰ spots left: {props.capacity}
        </Card.Text>
        <PopUp
          changed={props.changed}
          driveUpAccess={props.driveUpAccess}
          climateControlled={props.climateControlled}
          updateChanged={props.updateChanged}
          address={props.location}
          email={props.email}
          price={props.price}
          booked={props.booked}
          minDate={convertStringToDate(props.startDate)}
          maxDate={convertStringToDate(props.endDate)}
        />
      </Card.Body>
    </Card>
  );
}

export default Posting;
