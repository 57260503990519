import React from "react";
import ReactDOM from "react-dom/client";
import Listings from "./Components/Listings";
import Login from "./Components/Login";
import HomePage from "./Components/HomePage";
import PostAddress from "./Components/PostAddress";
import { BrowserRouter, Routes, Route } from "react-router-dom";

export default function App() {
  /* Routing Functionality */
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/listings" element={<Listings />} />
        <Route path="/" element={<Login />} />
        <Route path="/Home" element={<HomePage />}></Route>
        <Route path="/address" element={<PostAddress />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
