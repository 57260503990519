/* Google Maps API Imports */
import { useState, useEffect } from "react";

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

function PlacesAutocomplete(props) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,

    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
    defaultValue: props.val,
  });
  const handleInput = (e) => {
    setValue(e.target.value);
    props.updateVal(e.target.value);
  };

  const handleSelect =
    ({ description }) =>
    () => {
      //When user selects a place, we can replace the keyword without request data from the API
      //by setting the second parameter to false
      setValue(description, false);
      props.updateVal(description);
      clearSuggestions();

      /*getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
    }); */
    };

  useEffect(() => {
    fetch(
      "https://addressvalidation.googleapis.com/v1:validateAddress?key=AIzaSyCfJZaqhU0j8GQIKVbPjAUuKIUnBbl7xtk",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          address: {
            addressLines: [value],
          },
        }),
      },
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.result) {
          if (
            (data.result.verdict.validationGranularity == "PREMISE" ||
              data.result.verdict.validationGranularity == "SUB_PREMISE") &&
            data.result.address.postalAddress.regionCode === "US"
          ) {
            props.updateAddressComplete(true);
            props.updatePlaceID(data.result.geocode.placeId);
          } else {
            props.updateAddressComplete(false);
          }
        }
      })
      .catch((err) => {
        props.updateAddressComplete(false);
      });
  }, [value]);

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div>
      <input
        value={value}
        onChange={handleInput}
        disabled={false}
        placeholder="Enter Your Address"
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && <ul>{renderSuggestions()}</ul>}
    </div>
  );
}

export default PlacesAutocomplete;
