import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "./Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PostListing from "./PostListing";
import ListGroup from "react-bootstrap/ListGroup";
import Booking from "./Booking";
import Leasing from "./Leasing";
import * as Realm from "realm-web";

function HomePage() {
  /* STATE variables Start */

  const [emails, updateEmails] = useState([]);
  const [prices, updatePrices] = useState([]);
  const [startDates, updateStartDates] = useState([]);
  const [endDates, updateEndDates] = useState([]);
  const [names, updateNames] = useState([]);
  const [addresses, updateAddresses] = useState([]);
  const [climateControls, updateClimateControls] = useState([]);
  const [driverAccesses, updateDriverAccesses] = useState([]);

  const [name, updateName] = useState("");
  const [email, updateEmail] = useState("");

  const [ownEmails, ownUpdateEmails] = useState([]);
  const [ownPrices, ownUpdatePrices] = useState([]);
  const [ownStartDates, ownUpdateStartDates] = useState([]);
  const [ownEndDates, ownUpdateEndDates] = useState([]);
  const [ownNames, ownUpdateNames] = useState([]);
  const [ownAddresses, updateOwnAddresses] = useState([]);
  const [ownClimateControls, updateOwnClimateControls] = useState([]);
  const [ownDriverAccesses, updateOwnDriverAccesses] = useState([]);

  /* STATE variables end */

  const navigate = useNavigate();

  /* MONGODB Real Time Updates */

  const app = new Realm.App({ id: "application-0-odmvx" });
  const [user, setUser] = useState();
  const [events, setEvents] = useState([]);
  const [list, updateList] = useState([]);

  useEffect(() => {
    const login = async () => {
      const user = await app.logIn(Realm.Credentials.anonymous());
      setUser(user);

      //connect to the cluster

      const mongodb = app.currentUser.mongoClient("mongodb-atlas");

      const collection = mongodb.db("luggagefree").collection("owndates");

      for await (const change of collection.watch()) {
        setEvents((events) => [...events, change]);
        updateList([...list, change]);
      }
    };
    login();
  }, []);

  useEffect(() => {
    const update = async () => {
      if (list.length > 0 && email !== "") {
        var ownDate = list[list.length - 1].documentKey._id;

        const mongodb = app.currentUser.mongoClient("mongodb-atlas");
        const credentials = mongodb.db("luggagefree").collection("credentials");

        const document = await credentials.findOne({ email: email });
        var ownDates = document.ownDates;

        var s = new Set();

        for (let i = 0; i < ownDates.length; i++) {
          s.add(ownDates[i].toString());
        }

        if (s.has(ownDate.toString())) {
          var d = list[list.length - 1].fullDocument;
          ownUpdateEmails([...ownEmails, d.email]);
          ownUpdatePrices([...ownPrices, d.price]);
          ownUpdateStartDates([...ownStartDates, d.start]);
          ownUpdateEndDates([...ownEndDates, d.end]);
          ownUpdateNames([...ownNames, [d.first, d.last]]);
          updateOwnAddresses([...ownAddresses, d.address]);
          updateOwnClimateControls([
            ...ownClimateControls,
            d.climateControlled,
          ]);
          updateOwnDriverAccesses([...ownDriverAccesses, d.driveUpAccess]);
        }
      }
    };

    update();
  }, [list]);

  useEffect(() => {
    /* Render This Page Only If We Can*/

    fetch("/api/login", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": "true",
      },
      credentials: "include",
      withCredentials: true,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.SUCCESS === "false") {
          navigate("/");
        } else {
          fetch("/api/home", {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": "true",
            },
            credentials: "include",
            withCredentials: true,
          })
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              updateEmails(data.booked.emails);
              updatePrices(data.booked.prices);
              updateStartDates(data.booked.starts);
              updateEndDates(data.booked.ends);
              updateNames(data.booked.names);
              updateClimateControls(data.booked.climateControls);
              updateDriverAccesses(data.booked.driverAccesses);
              ownUpdateEmails(data.owned.emails);
              ownUpdatePrices(data.owned.prices);
              ownUpdateStartDates(data.owned.starts);
              ownUpdateEndDates(data.owned.ends);
              ownUpdateNames(data.owned.names);
              updateAddresses(data.booked.addresses);
              updateDriverAccesses(data.booked.driveUpAccesses);
              updateOwnAddresses(data.owned.ownAddresses);
              updateOwnClimateControls(data.owned.climateControls);
              updateOwnDriverAccesses(data.owned.driveUpAccesses);
            })
            .then(() => {});
        }
      });
  }, [navigate]);

  useEffect(() => {
    fetch("/api/name", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": "true",
      },
      credentials: "include",
      withCredentials: true,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        updateName(data.name);
        updateEmail(data.email);
      });
  }, []);

  return (
    <div>
      <Navigation></Navigation>

      <h1 style={{ marginTop: "1.5%", textAlign: "center" }} class="display-4">
        Welcome to Luggage Free, {name}
      </h1>
      <Container style={{ marginTop: "4%" }}>
        <Row className="justify-content-center">
          <Col>
            <h3 style={{ textAlign: "center" }}>🏘️ Your Bookings 🏘️ </h3>
            <div style={{ overflowY: "auto", maxHeight: "150px" }}>
              <ListGroup>
                {emails.map((e, i) => (
                  <Booking
                    email={e}
                    price={prices[i]}
                    startDate={startDates[i]}
                    endDate={endDates[i]}
                    name={names[i]}
                    address={addresses[i]}
                    climateControlled={climateControls[i]}
                    driveUpAccess={driverAccesses[i]}
                  />
                ))}
              </ListGroup>
            </div>
          </Col>

          <Col>
            <h3 style={{ textAlign: "center" }}>
              {" "}
              🏡 People You Are Leasing To 🏡{" "}
            </h3>
            <div style={{ overflowY: "auto", maxHeight: "320px" }}>
              <ListGroup>
                {ownEmails.map((e, i) => (
                  <Leasing
                    name={ownNames[i]}
                    price={ownPrices[i]}
                    startDate={ownStartDates[i]}
                    endDate={ownEndDates[i]}
                    email={ownEmails[i]}
                    address={ownAddresses[i]}
                    climateControlled={ownClimateControls[i]}
                    driveUpAccess={ownDriverAccesses[i]}
                  />
                ))}
              </ListGroup>
            </div>
          </Col>
        </Row>
      </Container>

      <PostListing></PostListing>
    </div>
  );
}
export default HomePage;
